import React from 'react';
import { Connector } from 'mqtt-react-hooks';

export default function MqttConnect(props) {
    if( props.access){
        const options = {
            clientId: props.access.id,
            password: props.access.id,
            username: props.access.id_user,
            port: 1883
        }

        return (
            <Connector brokerUrl={`wss://skif.me/ws`} options={options} >
                {props.children}
            </Connector>
        )
    }else{
        return("Error bad access")
    }

}
