import { useState, useEffect } from 'react';

export default function useAccess(token) {
    const [data, setData] = useState(null)
    const settings = {
        method: 'POST',
        headers: {
            'token':token
        }
    };


    useEffect(() => {   
        async function fetchApi() {
            let fetchResponse = await fetch('/api/acces',settings)
            const data = await fetchResponse.json();
            setData(data)
        }
        if(token)
            fetchApi();
    }, []);

    return data
}
