import { useState, useEffect } from 'react';

export default function useWatchers(token) {
    const [data, setData] = useState(null)
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'aplication/json',
            'token': token
        }
    };

    useEffect(() => {
        async function fetchApi() {
            try{
                let fetchResponse = await fetch('/api/watchers', settings)
                const data = await fetchResponse.json();
                setData(data)
            }catch{
                setData(null)
            }
       
        }
        fetchApi();
    }, []);

    return data
}
