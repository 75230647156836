import React from 'react';
import useWatchers from './useWatchers';
import Paper from '@material-ui/core/Paper';
import Map from '../Map/Map';
import { List, ListItem, Box, CardActionArea } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useMqttState, useSubscription } from 'mqtt-react-hooks';
import { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import convertToLatLng from '../Helpers/convertToLatLng';
import getFormatDate from '../Helpers/getFormatDate';
import { average } from 'geolocation-utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    fixedHeight: {
        height: "85vh",
    },
    fullHeight: {
        height: "100vh"
    },
    flexContainer: {
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 1000,
    },
    fixedWidth: {
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    contentEnd: {
        justifyContent: 'flex-end'
    }
}));

function findIndexByProperty(data, key, value) {
    for (var i = 0; i < data.length; i++) {
        if (data[i][key] === value) {
            return i;
        }
    }
    return -1;
}

function getNormalTopic(topic) {
    const firstString = getStringBeforeLastSlash(topic);
    return getStringBeforeLastSlash(firstString);
}

function getStringBeforeLastSlash(str) {
    return str.substring(0, str.lastIndexOf('/'));
}

export default function Content(props) {
    const classes = useStyles();
    const fixedWidthPaper = clsx(classes.paper, classes.fixedWidth);
    const watchers = useWatchers(props.token);
    const { connectionStatus } = useMqttState();
    const [mapData, setMapData] = useState([]);
    const [topics, setTopics] = useState([]);
    const [map, setMap] = useState(null)
    const { message } = useSubscription(topics);

    useEffect(() => {
        console.log(`status is ${connectionStatus}`)
        if (watchers) {
            setTopics(
                watchers.map((watcher) => {
                    return watcher.topic
                })
            );
            try {
                const initial = watchers.filter((watcher) => {
                    if (!watcher.jdata.hasOwnProperty('latlon')) {
                        return false; // skip
                    }
                    return true;
                }).map((watcher) => {
                    let initialData = {
                        topic: getStringBeforeLastSlash(watcher.topic),
                        message: watcher.jdata,
                        watcher: watcher
                    }
                    return initialData;
                })
                setMapData(
                    initial
                );

                const allPoints = initial.map((point) => {
                    const latLngString = point.message.latlon
                    const latLng = convertToLatLng(latLngString)
                    return [latLng.lat, latLng.lon]
                })
                const averagePosition = average(allPoints)
                let position
                if (averagePosition) { position = averagePosition } else { position = [51.505, -0.09] }
                if (map) {
                    map.setView(position, map.getZoom());
                }
            } catch { }
        }
    }, [connectionStatus, watchers, map]);


    useEffect(() => {
        async function decodeMessage(mess) {
            const data = await JSON.parse(mess);
            return data;
        }

        async function getMessage(){
            if (message) {
                const decodedMess = await decodeMessage(message.message);
                const topic = getNormalTopic(message.topic)
                const topicIndex = findIndexByProperty(mapData, 'topic', topic)
                const messageToSave = { ...message }
                messageToSave.topic = topic;
                messageToSave.message = decodedMess;
                const tmpWatchers = [...watchers]
                const fixedWatchers = tmpWatchers.map((watcher) => {
                    let res = { ...watcher };
                    res.topic = getStringBeforeLastSlash(watcher.topic);
                    return res
                })
                const watcherIndex = findIndexByProperty(fixedWatchers, 'topic', topic);
                messageToSave.watcher = watchers[watcherIndex];
                if (messageToSave.watcher) {
                    if (topicIndex > -1) {
                        const newData = [...mapData]
                        newData[topicIndex] = messageToSave;
                        setMapData([...newData]);
                    }
                    else {
                        const newData = [...mapData]
                        newData.push(messageToSave);
                        if (newData.watcher) {
                            setMapData(newData);
                        }
                    }
                }
            }
        }
        getMessage()
    }, [message, watchers,mapData]);

    function handleClick(item) {
        const latLngString = item.message.latlon;
        const latLng = convertToLatLng(latLngString);
        map.setView([latLng.lat, latLng.lon], map.getZoom(), { animate: true });
    }

    return (
        <Box>
            {/* Map */}
            <Box className={classes.fullHeight}>
                <Map data={mapData} setMap={setMap} />
            </Box>
            <List className={classes.flexContainer} >
                {mapData.map((item) =>
                    <ListItem className={classes.contentEnd} >
                        <CardActionArea onClick={() => handleClick(item)}>
                            <Paper className={fixedWidthPaper}>
                                <Typography component="h2" variant="h5">
                                    {item.watcher.name}
                                </Typography>
                           
                                <Typography variant="subtitle1" paragraph>
                                    от {getFormatDate(item.watcher.date_from)} до {getFormatDate(item.watcher.date_to)}
                                </Typography>
                            </Paper>
                        </CardActionArea>
                    </ListItem>
                )}
            </List>
        </Box>
    );
}