import './App.css';
import React from 'react';
import Dash from './Dashboard';
import useAccess from './useAccess';
import MqttConnect from '../Mqtt/MqttConnect';
import Content from './Content';

function App() {
  const queryString = require('query-string');
  const params = queryString.parse(window.location.search)
  const token = params.token
  const access = useAccess(token);
  if (!token) {
    return <h1>Error empty token</h1>;
  }
  if (!access) {
    return (<Loading></Loading>);
  } else {
    return (
      <div className="App">
        <Dash>
          <MqttConnect access={access}>
            <Content token={token} />
          </MqttConnect>
        </Dash>
      </div>
    );
  }
}

function Loading() {
  return (
    <div>
      Loading...
    </div>
  )
}

export default App;
