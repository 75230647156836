import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap } from 'react-leaflet'
import convertToLatLng from '../Helpers/convertToLatLng';
import getFormatDate from '../Helpers/getFormatDate';

function Map(props) {
    const data = props.data
    console.log('data');
    console.log(data);
    const position = [51.505, -0.09]
    return (
        <MapContainer center={position} zoom={13} scrollWheelZoom={false} zoomControl={false} whenCreated={props.setMap} scrollWheelZoom={true}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {data.map((point) => {
                if (point.watcher) {
                    const latLngString = point.message.latlon
                    const latLng = convertToLatLng(latLngString)
                    return (
                        <Marker position={[latLng.lat, latLng.lon]}>
                            <Popup>
                                <strong>Имя:</strong> {point.watcher.name}<br/>
                                Время: {getFormatDate(point.message.datepoint)}<br/>
                                Скорость: {point.message.speedkph} км/ч<br/>
                                Заряд: {point.message.pwr_ext}%<br/>
                            </Popup>
                        </Marker>
                    )
                }
            })}
            <ZoomControl position="bottomleft"/>
        </MapContainer>
    )
}

export default Map